import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from '../helper/commonApi'

const initialState = {
  user_name: '',
  is_admin: '',
  user_last_login: '',
  user_rating_submitted: '',
  company_list: [],
  company_data_available: false,
  selected_company_object: {},
  selected_year_object: {},
  user_verification_error: {
    error: '',
    message: ''
  },
  year_list: [],
  logout: false,
  user_fname_lname: ['', ''],
  user_reg_date: '',
  user_id: '',
  user_email: '',
  user_mobile: '',
  current_year: '',
  next_year: '',
  year_option_label: '',
  indicator_data: [],
  company_is_next: '',
  is_pass_available: false,
  is_logout_loader: false,
  is_profile_complete: false,
  profile_status: '',
  incomplete_user_profile: '',
  user_created: '',
  stepIdList: [],
  completedStepCount: 0,
  last_selected_company_id: '',
  notify_count: '',
  print_tab: '',
  popup_today_date: '',
  is_dashboard_amtshow: '',
  is_open_report: '',
  is_exist: '0',
  is_whatsapp_selected: 0,
  is_whatsapp_remember: 0,
  barcode_system_status: 0,
  barcode_menu: false,
  change_company: false,
  time_period: '',
  is_whatsapp_sharing: {},
  dashboard_loader: true,
  billing_plan_data: '',
  expand_balance_sheet: false,
  is_show_opening_balance: false
}

export const handleLogout = createAsyncThunk('header/logout', async (user_id) => {
  const header = { 'refresh-token': localStorage.getItem('refresh_tokens') }
  const finalObj = {
    userId: user_id
  }
  const response = await axios.post(`${apiEndpoint}/log-out`, finalObj, { headers: header })
  return response
})

export const commonSlice = createSlice({
  name: 'userName',
  initialState,
  reducers: {
    setUserLastLogin: (state, action) => {
      state.user_last_login = action.payload
    },
    setCompanyList: (state, action) => {
      state.company_list = action.payload
    },
    setCompanyDataAvailable: (state, action) => {
      state.company_data_available = action.payload
    },
    setSelectedCompanyObject: (state, action) => {
      state.selected_company_object = action.payload
    },
    resetUserDetail: (state) => {
      state.user_name = ''
    },
    setUserVerificationError: (state, action) => {
      state.user_verification_error = action.payload
    },
    setYearList: (state, action) => {
      state.year_list = [...action.payload]
    },
    setSelectedYearObject: (state, action) => {
      /**
       * IW0111
       * this condition used to display label whether financial year or not
       */
      if (state.current_year !== action.payload.year) {
        state.year_option_label = 'Selected Financial Year'
      } else {
        state.year_option_label = 'Current Financial Year'
      }
      /** 
       * IW0111
       * this calculation is used to find next year
       */
      const yearDigit = (state.current_year).split('-')[0].slice(0, 2)
      const next_year = state.current_year.split('-')[1]
      state.next_year = `${yearDigit}${next_year}-${Number(next_year) + 1}`
      state.selected_year_object = action.payload
    },
    setCurrentYear: (state, action) => {
      state.current_year = action.payload
    },
    setIndicatorData: (state, action) => {
      state.indicator_data = action.payload
    },
    setCompanyIsNext: (state, action) => {
      state.company_is_next = action.payload
    },
    setLogoutLoader: (state, action) => {
      state.is_logout_loader = action.payload
    },
    setCompleteProfile: (state, action) => {
      state.is_profile_complete = action.payload
    },
    setLastSelectedCompanyId: (state, action) => {
      state.last_selected_company_id = action.payload
    },
    setStockReport: (state, action) => {
      state.is_open_report = action.payload
    },
    setWhatsAppSelected: (state, action) => {
      state.is_whatsapp_selected = action.payload
    },
    setWhatsAppRemember: (state, action) => {
      state.is_whatsapp_remember = action.payload
    },
    setBarcodeSystemStatus: (state, action) => {
      state.selected_company_object.barcode_system_status = action.payload
    },
    setBarcodeMenu: (state, action) => {
      state.barcode_menu = action.payload
    },
    setUserInfo: (state, action) => {
      state.user_name = "user_name" in action.payload ? action.payload.user_name : state.user_name
      state.is_pass_available = "is_pass_available" in action.payload ? action.payload.is_pass_available : state.is_pass_available
      state.is_admin = "is_admin" in action.payload ? action.payload.is_admin : state.is_admin
      state.user_email = "user_email" in action.payload ? action.payload.user_email : state.user_email
      state.user_mobile = "user_mobile" in action.payload ? action.payload.user_mobile : state.user_mobile
      state.profile_status = "profile_status" in action.payload ? action.payload.profile_status : state.profile_status
      state.incomplete_user_profile = "incomplete_user_profile" in action.payload ? action.payload.incomplete_user_profile : state.incomplete_user_profile
      state.user_created = "user_created" in action.payload ? action.payload.user_created : state.user_created
      state.user_fname_lname = "user_fname_lname" in action.payload ? action.payload.user_fname_lname : state.user_fname_lname
      state.user_reg_date = "user_reg_date" in action.payload ? action.payload.user_reg_date : state.user_reg_date
      state.user_id = "user_id" in action.payload ? action.payload.user_id : state.user_id
      state.user_last_login = "user_last_login" in action.payload ? action.payload.user_last_login : state.user_last_login
      state.user_rating_submitted = "user_rating_submitted" in action.payload ? action.payload.user_rating_submitted : state.user_rating_submitted
      state.stepIdList = "stepIdList" in action.payload ? action.payload.stepIdList : state.stepIdList
      state.completedStepCount = "completedStepCount" in action.payload ? action.payload.completedStepCount : state.completedStepCount
      state.last_selected_company_id = "last_selected_company_id" in action.payload ? action.payload.last_selected_company_id : state.last_selected_company_id
      state.notify_count = "notify_count" in action.payload ? action.payload.notify_count : state.notify_count
      state.print_tab = "print_tab" in action.payload ? action.payload.print_tab : state.print_tab
      state.is_dashboard_amtshow = "is_dashboard_amtshow" in action.payload ? action.payload.is_dashboard_amtshow : state.is_dashboard_amtshow
      state.is_exist = "is_exist" in action.payload ? action.payload.is_exist : state.is_exist
      state.popup_today_date = "popup_today_date" in action.payload ? action.payload.popup_today_date : state.popup_today_date
      state.logout = "logout" in action.payload ? action.payload.logout : state.logout
    },
    setChangeCompany: (state, action) => {
      state.change_company = action.payload
    },
    setTimePeriod: (state, action) => {
      state.time_period = action.payload
    },
    setWhatsappSharing: (state, action) => {
      state.is_whatsapp_sharing = action.payload
    },
    setDashboardLoader: (state, action) => {
      state.dashboard_loader = action.payload
    },
    setBillingPlanData: (state, action) => {
      state.billing_plan_data = action.payload
    },
    setExpandingBalanceSheet: (state, action) => {
      state.expand_balance_sheet = action.payload
    },
    setIsShowOpTrialBalance: (state, action) => {
      state.is_show_opening_balance = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(handleLogout.fulfilled, (state) => {
        state.logout = true
      })
  }
})

// Action creators are generated for each case reducer function
export const {
  setUserLastLogin,
  setCompleteProfile,
  setCompanyList,
  setYearList,
  setSelectedYearObject,
  setSelectedCompanyObject,
  resetUserDetail,
  setUserVerificationError,
  setCurrentYear,
  setCompanyDataAvailable,
  setIndicatorData,
  setCompanyIsNext,
  setLogoutLoader,
  setLastSelectedCompanyId,
  setStockReport,
  setBarcodeSystemStatus,
  setBarcodeMenu,
  setUserInfo,
  setWhatsAppRemember,
  setWhatsAppSelected,
  setChangeCompany,
  setTimePeriod,
  setWhatsappSharing,
  setDashboardLoader,
  setBillingPlanData,
  setExpandingBalanceSheet,
  setIsShowOpTrialBalance } = commonSlice.actions

export default commonSlice.reducer